import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p align="center">
  <a href="https://www.gatsbyjs.com">
    <img alt="Gatsby" src="https://www.gatsbyjs.com/Gatsby-Monogram.svg" width="60" />
  </a>
    </p>
    <h1 align="center">
  Gatsby's default starter
    </h1>
    <p>{`Kick off your project with this default boilerplate. This starter ships with the main Gatsby configuration files you might need to get up and running blazing fast with the blazing fast app generator for React.`}</p>
    <p><em parentName="p">{`Have another more specific idea? You may want to check out our vibrant collection of `}<a parentName="em" {...{
          "href": "https://www.gatsbyjs.com/docs/gatsby-starters/"
        }}>{`official and community-created starters`}</a>{`.`}</em></p>
    <h2 {...{
      "id": "-quick-start"
    }}>{`🚀 Quick start`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Create a Gatsby site.`}</strong></p>
        <p parentName="li">{`Use the Gatsby CLI to create a new site, specifying the default starter.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`# create a new Gatsby site using the default starter
gatsby new my-default-starter https://github.com/gatsbyjs/gatsby-starter-default
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Start developing.`}</strong></p>
        <p parentName="li">{`Navigate into your new site’s directory and start it up.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`cd my-default-starter/
gatsby develop
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Open the source code and start editing!`}</strong></p>
        <p parentName="li">{`Your site is now running at `}<inlineCode parentName="p">{`http://localhost:8000`}</inlineCode>{`!`}</p>
        <p parentName="li"><em parentName="p">{`Note: You'll also see a second link: `}</em><inlineCode parentName="p">{`http://localhost:8000/___graphql`}</inlineCode><em parentName="p">{`. This is a tool you can use to experiment with querying your data. Learn more about using this tool in the `}<a parentName="em" {...{
              "href": "https://www.gatsbyjs.com/tutorial/part-five/#introducing-graphiql"
            }}>{`Gatsby tutorial`}</a>{`.`}</em></p>
        <p parentName="li">{`Open the `}<inlineCode parentName="p">{`my-default-starter`}</inlineCode>{` directory in your code editor of choice and edit `}<inlineCode parentName="p">{`src/pages/index.js`}</inlineCode>{`. Save your changes and the browser will update in real time!`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "🧐-whats-inside"
    }}>{`🧐 What's inside?`}</h2>
    <p>{`A quick look at the top-level files and directories you'll see in a Gatsby project.`}</p>
    <pre><code parentName="pre" {...{}}>{`.
├── node_modules
├── src
├── .gitignore
├── .prettierrc
├── gatsby-browser.js
├── gatsby-config.js
├── gatsby-node.js
├── gatsby-ssr.js
├── LICENSE
├── package-lock.json
├── package.json
└── README.md
`}</code></pre>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`/node_modules`}</inlineCode></strong>{`: This directory contains all of the modules of code that your project depends on (npm packages) are automatically installed.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`/src`}</inlineCode></strong>{`: This directory will contain all of the code related to what you will see on the front-end of your site (what you see in the browser) such as your site header or a page template. `}<inlineCode parentName="p">{`src`}</inlineCode>{` is a convention for “source code”.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`.gitignore`}</inlineCode></strong>{`: This file tells git which files it should not track / not maintain a version history for.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`.prettierrc`}</inlineCode></strong>{`: This is a configuration file for `}<a parentName="p" {...{
            "href": "https://prettier.io/"
          }}>{`Prettier`}</a>{`. Prettier is a tool to help keep the formatting of your code consistent.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`gatsby-browser.js`}</inlineCode></strong>{`: This file is where Gatsby expects to find any usage of the `}<a parentName="p" {...{
            "href": "https://www.gatsbyjs.com/docs/browser-apis/"
          }}>{`Gatsby browser APIs`}</a>{` (if any). These allow customization/extension of default Gatsby settings affecting the browser.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`gatsby-config.js`}</inlineCode></strong>{`: This is the main configuration file for a Gatsby site. This is where you can specify information about your site (metadata) like the site title and description, which Gatsby plugins you’d like to include, etc. (Check out the `}<a parentName="p" {...{
            "href": "https://www.gatsbyjs.com/docs/gatsby-config/"
          }}>{`config docs`}</a>{` for more detail).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`gatsby-node.js`}</inlineCode></strong>{`: This file is where Gatsby expects to find any usage of the `}<a parentName="p" {...{
            "href": "https://www.gatsbyjs.com/docs/node-apis/"
          }}>{`Gatsby Node APIs`}</a>{` (if any). These allow customization/extension of default Gatsby settings affecting pieces of the site build process.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`gatsby-ssr.js`}</inlineCode></strong>{`: This file is where Gatsby expects to find any usage of the `}<a parentName="p" {...{
            "href": "https://www.gatsbyjs.com/docs/ssr-apis/"
          }}>{`Gatsby server-side rendering APIs`}</a>{` (if any). These allow customization of default Gatsby settings affecting server-side rendering.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`LICENSE`}</inlineCode></strong>{`: This Gatsby starter is licensed under the 0BSD license. This means that you can see this file as a placeholder and replace it with your own license.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`package-lock.json`}</inlineCode></strong>{` (See `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` below, first). This is an automatically generated file based on the exact versions of your npm dependencies that were installed for your project. `}<strong parentName="p">{`(You won’t change this file directly).`}</strong></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`package.json`}</inlineCode></strong>{`: A manifest file for Node.js projects, which includes things like metadata (the project’s name, author, etc). This manifest is how npm knows which packages to install for your project.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`README.md`}</inlineCode></strong>{`: A text file containing useful reference information about your project.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "-learning-gatsby"
    }}>{`🎓 Learning Gatsby`}</h2>
    <p>{`Looking for more guidance? Full documentation for Gatsby lives `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.com/"
      }}>{`on the website`}</a>{`. Here are some places to start:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`For most developers, we recommend starting with our `}<a parentName="strong" {...{
              "href": "https://www.gatsbyjs.com/tutorial/"
            }}>{`in-depth tutorial for creating a site with Gatsby`}</a>{`.`}</strong>{` It starts with zero assumptions about your level of ability and walks through every step of the process.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`To dive straight into code samples, head `}<a parentName="strong" {...{
              "href": "https://www.gatsbyjs.com/docs/"
            }}>{`to our documentation`}</a>{`.`}</strong>{` In particular, check out the `}<em parentName="p">{`Guides`}</em>{`, `}<em parentName="p">{`API Reference`}</em>{`, and `}<em parentName="p">{`Advanced Tutorials`}</em>{` sections in the sidebar.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "-deploy"
    }}>{`💫 Deploy`}</h2>
    <p><a parentName="p" {...{
        "href": "https://app.netlify.com/start/deploy?repository=https://github.com/gatsbyjs/gatsby-starter-default"
      }}><img alt="Deploy to Netlify" src="https://www.netlify.com/img/deploy/button.svg" /></a></p>
    <p><a parentName="p" {...{
        "href": "https://vercel.com/import/project?template=https://github.com/gatsbyjs/gatsby-starter-default"
      }}><img alt="Deploy with Vercel" src="https://vercel.com/button" /></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      